<template>
  <div class="meet-info-container full-screen">
    <div
      class="meet-info-main"
      :style="`${isHaveCourseware ? '' : 'display:flex;height:100%;'}`"
    >
      <div class="meet-info-title global-color">
        {{ isHaveCourseware ? "基本信息修改" : "会议基本信息" }}
      </div>
      <div class="meet-info-row">
        <div class="meet-info-row-key">
          <span v-for="text in '会议日期'" :key="text">{{ text }}</span>
        </div>
        <span class="meet-info-space">：</span>
        <div class="meet-info-row-value">
          <el-date-picker
            v-model="baseData.date"
            :disabled-date="verifyDate"
            value-format="YYYY-MM-DD"
            type="date"
            @change="changeDate"
            clearable
            :editable="false"
            placeholder="请选择日期"
          />
        </div>
      </div>
      <div class="meet-info-row">
        <div class="meet-info-row-key">
          <span v-for="text in '会议开始时间'" :key="text">{{ text }}</span>
        </div>
        <span class="meet-info-space">：</span>
        <div class="meet-info-row-value basic_info_time">
          <el-select
            v-model.trim="baseData.startHour"
            @change="changeTime"
            placeholder="时"
          >
            <el-option
              v-for="item in config.startHours"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
          <span class="meet-info-row-symbol">:</span>
          <el-select
            v-model.trim="baseData.startMinute"
            @change="changeTime"
            class="global-input"
            placeholder="分"
          >
            <el-option
              v-for="item in config.minutes"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </div>
      </div>
      <div class="meet-info-row">
        <div class="meet-info-row-key">
          <span v-for="text in '会议主题'" :key="text">{{ text }}</span>
        </div>
        <span class="meet-info-space">：</span>
        <div
          class="meet-info-row-value"
          style="
            font-weight: 400;
            font-size: 0.433333rem;
            color: #606060;
            line-height: 30px;
            margin-left: 20px;
          "
          :style="{ color: data.meet_topics ? '#606060' : '#c0c4cc' }"
        >
          {{ data.meet_topics ? data.meet_topics : "请选择课件" }}
          <!-- <el-input v-model.trim="data.meet_topics" placeholder="请选择课件">
          </el-input> -->
        </div>
      </div>
      <div class="meet-info-title meet-info-courseware global-color">
        {{ isHaveCourseware ? "已选课件" : "课件列表" }}
      </div>
      <div class="meet-info-courseware-list" v-if="!isHaveCourseware">
        <ListItem @getStorageDate="getStorageDate"></ListItem>
      </div>
      <PPTPlayer
        :data="meetInfo.courseware_info || coursewareData.courseware_info"
        v-if="isHaveCourseware && meetInfo.courseware_info?.length"
      ></PPTPlayer>
      <div
        class="meet-info-courseware-oneself"
        v-if="isHaveCourseware && meetInfo.courseware_info?.length == 0"
      >
        <div class="meet-info-courseware-oneself-load">
          <el-icon class="is-loading">
            <Loading />
          </el-icon>
          课件解析中请稍等
        </div>
      </div>
      <div
        class="meet-info-button-group"
        v-if="isHaveCourseware && meetInfo.courseware_info?.length"
      >
        <CustomButton
          class="meet-info-button"
          @click="changeCourseare"
          v-if="
            !meetInfo.id ||
            meetInfo.audit_status == 30 ||
            meetInfo.status === 0 ||
            meetInfo.status == 4
          "
          >更换课件</CustomButton
        >
        <CustomButton class="meet-info-button" @click="downLoadCourseware"
          >下载课件</CustomButton
        >
        <CustomButton
          class="meet-info-button"
          @click="uploadCourseware"
          v-if="
            !meetInfo.id ||
            meetInfo.audit_status == 30 ||
            meetInfo.status == 0 ||
            meetInfo.status == 4
          "
          >上传课件</CustomButton
        >
      </div>
      <div class="meet-info-btn">
        <div
          class="meet-info-submit global-background"
          @click="$router.back()"
          v-if="
            meetInfo.audit_status != 30 &&
            meetInfo.status >= 1 &&
            meetInfo.status < 4
          "
        >
          返回
        </div>
        <div
          v-else
          class="meet-info-submit global-background"
          @click="doCommit"
        >
          {{ meetInfo.id ? "修改" : "提交" }}
        </div>
      </div>
    </div>
    <input
      type="file"
      v-show="false"
      ref="resetFile"
      v-if="resetFile"
      @change="changeFile"
    />
  </div>
</template>
<script>
import { ElDatePicker, ElInput, ElOption, ElSelect } from "element-plus";
import ListItem from "@/components/page/courseware/components/list";
import PPTPlayer from "@/components/player/PPTPlayer";
import CustomButton from "@/components/unit/CustomButton";
import uploads from "@/common/uploads";
import { Loading } from "@element-plus/icons";
export default {
  name: "basicInfo",
  components: {
    ElDatePicker,
    ElOption,
    ElInput,
    ElSelect,
    ListItem,
    PPTPlayer,
    CustomButton,
    Loading,
  },
  props: {
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      pptSlides: [],
      meetInfo: {},
      data: {
        meet_topics: "",
        meet_start_time: "",
        meet_end_time: "",
        user_id: 0,
        literature_id: 0,
      },
      baseData: {
        date: "",
        startHour: "",
        startMinute: "",
        endHour: "",
        endMinute: "",
      },
      list: [
        "https://kclass.imedunion.com/class/17/83/courseware/new_文献1：KEYNOTE 181 中帕博利珠单抗与化疗作为晚期食管癌二线治疗对健康相关生活质量的影响 -v5/images/2a09028a014416be8ca48c0f1aca8521_1.png",
        "https://kclass.imedunion.com/class/17/83/courseware/new_文献1：KEYNOTE 181 中帕博利珠单抗与化疗作为晚期食管癌二线治疗对健康相关生活质量的影响 -v5/images/2a09028a014416be8ca48c0f1aca8521_2.png",
        "https://kclass.imedunion.com/class/17/83/courseware/new_文献1：KEYNOTE 181 中帕博利珠单抗与化疗作为晚期食管癌二线治疗对健康相关生活质量的影响 -v5/images/2a09028a014416be8ca48c0f1aca8521_3.png",
        "https://kclass.imedunion.com/class/17/83/courseware/new_文献1：KEYNOTE 181 中帕博利珠单抗与化疗作为晚期食管癌二线治疗对健康相关生活质量的影响 -v5/images/2a09028a014416be8ca48c0f1aca8521_4.png",
        "https://kclass.imedunion.com/class/17/83/courseware/new_文献1：KEYNOTE 181 中帕博利珠单抗与化疗作为晚期食管癌二线治疗对健康相关生活质量的影响 -v5/images/2a09028a014416be8ca48c0f1aca8521_5.png",
        "https://kclass.imedunion.com/class/17/83/courseware/new_文献1：KEYNOTE 181 中帕博利珠单抗与化疗作为晚期食管癌二线治疗对健康相关生活质量的影响 -v5/images/2a09028a014416be8ca48c0f1aca8521_6.png",
        "https://kclass.imedunion.com/class/17/83/courseware/new_文献1：KEYNOTE 181 中帕博利珠单抗与化疗作为晚期食管癌二线治疗对健康相关生活质量的影响 -v5/images/2a09028a014416be8ca48c0f1aca8521_7.png",
        "https://kclass.imedunion.com/class/17/83/courseware/new_文献1：KEYNOTE 181 中帕博利珠单抗与化疗作为晚期食管癌二线治疗对健康相关生活质量的影响 -v5/images/2a09028a014416be8ca48c0f1aca8521_8.png",
      ],
      config: {
        startHours: [],
        endHours: [],
        minutes: ["00", "30"],
        area: [8, 20],
      },
      isHaveCourseware: true,
      loading: false,
      resetFile: true,
      coursewareData: {},
      timer: null,
    };
  },
  async created() {
    if (this.$route.query.meet_id) {
      await this.loadData();
    } else {
      this.initData();
    }
    this.coursewareData = this.$tools.getStorage("coursewareData") || {};
    let meetData = this.$tools.getStorage("meetData");
    if (meetData?.date) {
      this.baseData = meetData;
    }
    this.isHaveCourseware = Boolean(
      this.coursewareData?.literature_id || this.meetInfo?.literature_id
    );
    if (this.coursewareData?.literature_id) {
      this.data = { ...this.coursewareData };
      this.meetInfo.courseware_info = this.coursewareData.courseware_info;
    } else {
      this.data = {
        literature_id: this.meetInfo.literature_id,
        meet_topics: this.meetInfo.meet_topics,
        courseware_info: this.meetInfo.courseware_info,
      };
    }

    this.data.user_id = this.$route.query.user_id;
  },
  methods: {
    async loadData() {
      let url = this.$tools.getURL(this.$urls.meet.meetInfo, {
        id: this.$route.query.meet_id,
      });
      await this.$axios
        .get(url)
        .then((res) => {
          this.meetInfo = res.data;
          if (res.data.courseware_info?.length == 0) {
            this.getCoursewareInfo(res.data.literature_id);
          }
          this.initData();
        })
        .catch((err) => {});
    },
    changeCourseare() {
      let userInfo = this.$tools.getStorage("userLogin", localStorage) || {};
      if (userInfo?.is_sale == 0) {
        this.getStorageDate();
        this.$router.push({
          path: "/courseware/list",
          query: { ...this.$route.query, is_sale: this.$route.query.is_sale },
        });
        return;
      } else {
        this.isHaveCourseware = !this.isHaveCourseware;
      }
    },
    initData() {
      this.data.meet_start_time = this.meetInfo.meet_start_time;
      this.data.meet_end_time = this.meetInfo.meet_end_time;
      this.initBaseData();
      this.initHours(this.$route.query.meet_id ? false : true);
    },
    initBaseData() {
      let [startDate, startHour, startMinute] = this.$tools
        .getDate(this.data.meet_start_time, "yyyy-MM-dd hh:mm")
        .split(/[\s:]/);
      let [endHour, endMinute] = this.$tools
        .getDate(this.data.meet_end_time, "hh:mm")
        .split(":");
      this.baseData.startHour = startHour;
      this.baseData.startMinute = startMinute;
      this.baseData.endHour = endHour;
      this.baseData.endMinute = endMinute;
      this.baseData.date = startDate;
    },
    initHours(status = false) {
      let hour, startIndex, minute;
      if (!this.isMoreThanMeetDate()) {
        hour = Number(this.$tools.getDate("", "hh"));
        minute = Number(this.$tools.getDate("", "mm"));
        startIndex = hour > this.config.area[0] ? hour : this.config.area[0];
        if (minute > 30) {
          startIndex += 1;
        }
        if (!this.isToday()) {
          startIndex = 23;
        }
        console.log(
          startIndex,
          hour,
          this.config.area,
          hour > this.config.area[0]
        );
        if (status && this.baseData.startHour) {
          let startTime = this.mergetTime();
          if (this.$tools.getTime(startTime) < Date.now()) {
            this.clearBaseData();
          }
        }
      } else {
        [startIndex] = this.config.area;
      }
      console.log(startIndex, hour, this.isMoreThanMeetDate());
      this.config.startHours = [];
      for (let index = startIndex; index <= this.config.area[1]; index++) {
        this.config.startHours.push(this.$tools.getTimeText(index));
      }
      console.log(" this.baseData :>> ", this.baseData);
    },
    clearBaseData() {
      this.baseData.date = "";
      this.baseData.startHour = "";
      this.baseData.startMinute = "";
      this.baseData.endHour = "";
      this.baseData.endMinute = "";
    },
    isToday() {
      return this.baseData.date == this.$tools.getDate();
    },
    isMoreThanMeetDate() {
      return (
        this.$tools.getTime(this.baseData.date) >
        this.$tools.getTime(this.$tools.getDate())
      );
    },
    mergetTime(status = false) {
      if (
        !(
          this.baseData.date &&
          this.baseData.startHour &&
          this.baseData.startMinute
        ) &&
        !status
      )
        return "";
      return `${this.baseData.date} ${this.baseData.startHour}:${
        this.baseData.startMinute || "00"
      }:00`;
    },
    changeDate() {
      this.initHours(false);
    },
    changeTime() {
      if (!this.baseData.startMinute) {
        this.baseData.startMinute = "00";
      }
      this.data.meet_start_time = this.mergetTime();
      this.data.meet_end_time = this.$tools.getDate(
        this.$tools.getTime(this.data.meet_start_time) + 110 * 60 * 1000,
        "yyyy-MM-dd hh:mm:ss"
      );
      this.initBaseData();
    },
    verifyDate(date) {
      return (
        this.$tools.getTime(date) < this.$tools.getTime(this.$tools.getDate())
      );
    },
    verifyData() {
      if (!this.baseData.date) {
        this.$tips.error({ text: "请选择开始日期" });
        return false;
      }
      if (!this.baseData.startHour) {
        this.$tips.error({ text: "请选择开始时间" });
        return false;
      }
      if (this.$tools.getTime(this.mergetTime()) < Date.now()) {
        this.$tips.error({ text: "会议时间小于当前时间,请重新选择会议时间" });
        return false;
      }

      if (this.data.courseware_info && !this.data.meet_topics) {
        if (!this.data.meet_topics) {
          this.$tips.error({ text: "请输入会议主题" });
          return false;
        }
      }
      if (!this.data.meet_topics) {
        this.$tips.error({ text: "请选择会议主题" });
        return false;
      }
      this.data.meet_start_time = this.mergetTime();
      this.data.meet_end_time = this.$tools.getDate(
        this.$tools.getTime(this.data.meet_start_time) + 110 * 60 * 1000,
        "yyyy-MM-dd hh:mm:ss"
      );
      return true;
    },
    doCommit() {
      if (this.loading || !this.verifyData()) return;
      this.$route.query.meet_id ? this.doUpdate() : this.doCreate();
    },
    doCreate() {
      let loading = this.$tips.loading();
      this.loading = true;
      this.$axios
        .post(this.$urls.meet.create, this.data)
        .then(() => {
          setTimeout(() => {
            this.loading = false;
            this.$tools.setStorage("coursewareData", {});
            this.$tools.setStorage("meetData", {});
            loading.close();
            this.$router.push({
              path: "/adminInfo",
              query: {
                id: this.$route.query.user_id,
              },
            });
          }, 1000);
        })
        .catch(() => {
          loading.close();
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    doUpdate() {
      let form = this.$route.query.form;
      let url = this.$urls.meet.meetPatch;
      if (form == "/doctorInfo") {
        url = this.$urls.meet.meetUserPatch;
      }
      let loading = this.$tips.loading();
      this.loading = true;
      this.$axios
        .patch(url, {
          ...this.data,
          id: this.meetInfo.id,
          user_id: this.meetInfo.user_id,
        })
        .then(() => {
          setTimeout(() => {
            this.loading = false;
            this.$tools.setStorage("coursewareData", {});
            this.$tools.setStorage("meetData", {});
            loading.close();
            this.$router.push({
              path: form,
              query: {
                id: this.$route.query.user_id,
              },
            });
          }, 1000);
        })
        .catch((err) => {
          loading.close();
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    downLoadCourseware() {
      window.open(
        this.coursewareData.courseware_url || this.meetInfo.courseware_url
      );
      // window.location.href =
      //   this.coursewareData.courseware_url || this.meetInfo.courseware_url;
      // this.$tips.success({ text: "下载成功" });
    },
    getStorageDate() {
      this.$tools.setStorage("meetData", this.baseData);
    },
    uploadCourseware() {
      this.$refs.resetFile.click();
      // this.$tips.success({ text: "上传成功" });
    },
    async changeFile(e) {
      let file = e.target.files[0];
      this.resetFile = false;
      this.$nextTick(() => {
        this.resetFile = true;
      });
      if (!file) return;
      let loading = this.$tips.loading();
      this.loading = true;
      let res = await uploads.uploadFile({
        file,
        reg: "pptx|ppt|pdf",
        key: "files",
      });
      if (!res) {
        this.loading = false;
        loading.close();
      }
      let courseware_url = res.data[0];
      this.$axios
        .post(this.$urls.literature.uploadCourseware, {
          courseware_url,
          meet_id: this.$route.query.meet_id || 0,
        })
        .then((res) => {
          this.data.literature_id = res.data.literature_id;
          this.data.meet_topics = res.data.courseware_title;
          this.meetInfo.courseware_info = [];
          if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
          }
          setTimeout(() => {
            this.getCoursewareInfo(res.data.literature_id);
            this.loading = false;
            loading.close();
          }, 1000);
        })
        .catch((err) => {
          loading.close();
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    async getCoursewareInfo(literature_id) {
      let url = this.$tools.getURL(this.$urls.literature.coursewareInfo, {
        literature_id: literature_id,
      });
      await this.$axios
        .get(url)
        .then((res) => {
          console.log("res.data.length :>> ", res.data.length);
          if (res.data.length > 0) {
            this.isHaveCourseware = true;
            this.meetInfo.courseware_info = res.data;
            if (this.timer) {
              clearTimeout(this.timer);
              this.timer = null;
              console.log("this.timer :>> ", this.timer);
            }
          } else {
            this.timer = setTimeout(() => {
              this.getCoursewareInfo(literature_id);
            }, 1000 * 60);
          }
        })
        .catch((err) => {});
    },
  },
  beforeUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  },
};
</script>
<style>
.meet-info-container {
  width: 100%;
  position: relative;
  justify-content: center;
  padding: 21px;
  overflow: auto;
}

.meet-info-container-bg {
  background: #434343;
  opacity: 0.8;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.meet-info-main {
  position: relative;
  background: #ffffff;
  display: flex;
  flex-direction: column;
}

.meet-info-title {
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  padding: 9px 0;
  border-bottom: 1px dashed #ccc;
}

.meet-info-row {
  display: flex;
  align-items: center;
  /* padding: 9px 0; */
  border-bottom: 1px dashed #ccc;
}

.meet-info-row-key {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 83px;
  font-weight: 400;
  font-size: 13px;
  color: #606060;
}

.meet-info-row-value {
  flex: 1 0 0;
  margin-left: 10px;
  display: flex;
  color: #221815;
  align-items: center;
  /* min-height: 40px; */
}
.meet-info-space {
  font-size: 13px;
}

.meet-info-btn {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.meet-info-main .el-input__inner {
  padding-left: 10px;
}
.meet-info-main .el-select,
.meet-info-main .el-date-editor,
.meet-info-main .el-input,
.meet-info-main input {
  border: none;
}

.meet-info-main .el-input__prefix {
  display: none;
}
.meet-info-submit {
  width: 198px;
  /* height: 26px; */
  text-align: center;
  line-height: 26px;
  border-radius: 14px;
  color: #fff;
  font-size: 14px;
}

.meet-info-submit .icon-img {
  width: 11px;
  height: 14px;
  margin-right: 5px;
}

.meet-info-row-symbol {
  font-size: 16px;
  margin: 0 10px;
}

.meet-info-row-value .el-date-editor--date {
  width: 100%;
}
.meet-info-courseware {
  margin-top: 20px;
}
.meet-info-courseware-list {
  flex: 1 0 0;
  overflow: hidden;
  margin-top: 10px;
}
.meet-info-button-group {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
}
.meet-info-button {
  width: 90px !important;
  height: 26px;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}
.meet-info-courseware-oneself {
  background: #fcd6cc;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.meet-info-courseware-oneself-load {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.meet-info-courseware-oneself-load .is-loading {
  font-size: 50px;
  margin-bottom: 10px;
}
.loading_button {
  font-size: 2rem;
  color: #126de3;
  animation: round 1s infinite;
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
</style>